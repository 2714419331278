/* global chrome */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, List, ButtonBase, IconButton, Typography, Badge, CardActionArea, Box, Paper, Chip } from '@mui/material';
import { red, grey } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CircularProgress from '@mui/material/CircularProgress';


import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import Comment from 'components/openedContent/comment';
import CommentForm from 'components/openedContent/forms/commentForm';


import { Clear, RateReview,  } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
//import produce from 'immer';
import { useImmer } from "use-immer";
import { useCallback } from 'react';


const Content = (props) => {
	
	const { query } = props;
	
  const [pageNum, setPageNum] = useState(0);
  const [imgLoaded, setImgLoaded] = useState(true);
  const [isCommentLoadingCompleted, setIsCommentLoadingCompleted] = useState(false);
  const [isCommentFormClicked, setIsCommentFormClicked] = useState(false);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const { root, extensionInstalled, appType, extensionId } = useSelector(state => state.api);

	const { loadedTime } = props;

	/*
		const makeUpdater = (updateValue) => {
			return updater => {
				updateValue(
					produce(updater)
				);
		}}
		
		const updateContent = makeUpdater(setContent); 
	*/

	const [content, updateContent] = useImmer(
		props.content
	);

  //const handleOpen = () => dispatch({ type: 'OPEN_DIA_$', content, updateOriginal: updateContent, loaded_time: new Date().toUTCString() });

	/*
	useEffect(() => {
		setContent( 
			props.content
		)
  }, [props.content]) 
  */

	const	handleCommentFormClick = () => {
		setIsCommentFormClicked(prev => !prev)
	};

	const handleContentLike = () => {
    if(!user)return dispatch({ type: 'OPEN_SIGN_$'})
		if(content.userReaction.length === 0){
			fetch(`${root}/api/reaction/content/${content._id}/1/0`, { method: 'put', credentials: 'include' })
				.then(res => console.log('reaction update success'))
				.catch(err => console.log(err))
			updateContent(draft => { 
				draft.likeCount++; 
				draft.userReaction = [{ type: 1 }]
			})
			return;
		} else {
		switch (content.userReaction[0].type){ 
			case -1:
				fetch(`${root}/api/reaction/content/${content._id}/1/-1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.likeCount++; 
					draft.dislikeCount--;
					draft.userReaction[0].type = 1;
				});
				break;
			case 0:
				fetch(`${root}/api/reaction/content/${content._id}/1/0`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.likeCount++; 
					draft.userReaction[0].type = 1;
				})
				break;
			case 1:
				fetch(`${root}/api/reaction/content/${content._id}/1/1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.likeCount--; 
					draft.userReaction[0].type = 0;
				})
				break;
		}
		}
	}

	const handleContentDislike = () => {
    if(!user)return dispatch({ type: 'OPEN_SIGN_$' });
		if(content.userReaction.length === 0){
			fetch(`${root}/api/reaction/content/${content._id}/-1/0`, { method: 'put', credentials: 'include' })
				.then(res => console.log('reaction update success'))
				.catch(err => console.log(err))
			updateContent(draft => { 
				draft.dislikeCount++; 
				draft.userReaction = [{ type: -1 }];
			})
			return;
		} else {
		switch (content.userReaction[0].type){ 
			case -1:
				fetch(`${root}/api/reaction/content/${content._id}/-1/-1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.dislikeCount--;
					draft.userReaction[0].type = 0;
				})
				break;
			case 0:
				fetch(`${root}/api/reaction/content/${content._id}/-1/0`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.dislikeCount++; 
					draft.userReaction[0].type = -1;
				})
				break;
			case 1:
				fetch(`${root}/api/reaction/content/${content._id}/-1/1`, { method: 'put', credentials: 'include' })
					.then(res => console.log('reaction update success'))
					.catch(err => console.log(err))
				updateContent(draft => { 
					draft.likeCount--; 
					draft.dislikeCount++; 
					draft.userReaction[0].type = -1;
				})
				break;
			} 
		}}

		const handleClearClick = e => {
			dispatch({type: 'HIDE_ON_ACTIVITIES', content })
			e.stopPropagation();			
			e.preventDefault();
		}

  /*
	let comments = 
		content.comments.length > 0 
		?
			<div style={{display:'flext', flexDirection: 'column'}}>
				{content.comments.map((comment, index) => (
					<Comment contentId={content._id} comment={comment} updateContent={updateContent}/>
				))}
			</div>
		:
			null
		;

		action={
			<IconButton aria-label="settings">
				<MoreVertIcon />
			</IconButton>
    */

    const { metas } = content;
    let thumbnail, description;
    let isImgHighlighted;
    if(content.comments.length>0&&content.comments[0].imgUrl){
    	thumbnail = content.comments[0].imgUrl;
    	isImgHighlighted = true;
    } else if(metas){
      thumbnail = 
	      metas['og:image']&&metas['og:image'].charAt(0) === '/'? 
	      	'http://'+ content.urls[0].host + metas['og:image'] 
	      : 
	      metas['og:image']?
	      	metas['og:image']
	      :
	      	null;
      description = metas['description']||metas['Description']||metas['og:description']||null;
    }

    const shortDomain = content.urls[0].hostname.substring(0, 4) === 'www.'? content.urls[0].hostname.substring(4) : content.urls[0].hostname;
  	function openCenteredWindow(url) {

		// Get the dimensions of the current window
		var currentWidth = window.screen.width;
		var currentHeight = window.screen.height;
		
		// Calculate 80% of the current window size
		var newWidth = currentWidth * 0.8;
		var newHeight = currentHeight * 0.8;
		
		// Calculate the top-left position to center the new window
		var left = (currentWidth - newWidth) / 2;
		var top = (currentHeight - newHeight) / 2;
		
		console.log('openCenter url is', currentWidth, newWidth, left, top)
	    // Create the features string
	    var windowFeatures = `width=${newWidth},height=${newHeight},left=${left},top=${top},resizable=yes,scrollbars=yes`;

	    // Open the new window
	    var windowName = "newCenteredWindow"; // Name of the new window

	    window.open(url, windowName, windowFeatures);
	} 

    const handleAreaClick = e => {
    	if(appType == 'EXTENSION'){
	    	chrome.storage.sync.set({isVisible: true})
	     	chrome.runtime.sendMessage(extensionId, {type: 'OPEN_WEBPAGE'}, function(response) {
			    let href = content.urls[0].href;
			    var url = new URL(href);
			    url.searchParams.append('platfor_content_objectId', content._id);
			    url.searchParams.append('platfor_comment_objectId', content.comments[0]._id)
			    window.open(url.toString(), '_blank');
			    //window.open(url.toString(), '_blank', `location=yes,height=${content.comments[0].documentHeight}, width=${content.comments[0].documentWidth},scrollbars=yes,status=yes`)
			})
    	} else {
    		if(!extensionInstalled){
    			const okay = window.confirm(
					"Please install the extension to open the original element"
				)
				if(okay){
					window.open('https://chrome.google.com/webstore/detail/socialbrowse-chrome/malnhaodjdegcljjebmpopbjmckblndn')
					return;
				} else {
					let href = content.urls[0].href;
					var url = new URL(href);
					url.searchParams.append('platfor_content_objectId', content._id);
					url.searchParams.append('platfor_comment_objectId', content.comments[0]._id)
					openCenteredWindow(content.urls[0].href);
					return;
				}
    		}
	      chrome.runtime.sendMessage(extensionId, {type: 'OPEN_WEBPAGE'}, function(response) {
			    let href = content.urls[0].href;
			    var url = new URL(href);
			    url.searchParams.append('platfor_content_objectId', content._id);
			    if(content.comments.length>0) url.searchParams.append('platfor_comment_objectId', content.comments[0]._id)
			    //window.open(url.toString(), '_blank');
			  	openCenteredWindow(url.toString());
			    //window.open(url.toString(), '_blank', `location=yes,height=${content.comments[0].documentHeight}, width=${content.comments[0].documentWidth},scrollbars=yes,status=yes`)
			  })
    	}
		}

		const comment = content.comments[0];

		const imgSize = comment?.highlightData.elementWidth
		
    return(

    	<div> 
    	<CardActionArea onClick={handleAreaClick} style={{ textAlign: 'center' }}>
			<CardHeader
				//className='simple_card_action'
				id='NO_BODY_EVENT'
				avatar={
					<Avatar 
						style={{background: 'white', width: 1/props.moveScale * 20, height: 1/props.moveScale * 20}} 
						alt={content.title||""} 
						src={'https://' + content.urls[0].host + '/favicon.ico'}
					/>
				}
				title={content.title}
				
				titleTypographyProps={{variant:'subtitle1', style: { 
					fontSize: `${1/props.moveScale * 20}px`,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					textAlign: 'left',
				}}}
				action={
					null
					/*
						<IconButton size='small' onClick={handleClearClick}>
							<Clear fontSize='small' />
						</IconButton>
					*/
				}
			/>
	      {
	      	!props.isBookmarkLayout
	      	?
      	    <>
				<div>
		            <img
		              src={props.action.elementScreenshotUrl}
		              //component='img'
		              onError={
		              	e => setImgLoaded(false)
		              }
					  loading='lazy'
		              style ={{
		              	// borderRadius: '20px',
		              	maxWidth: '100%',
						width: `${props.action.highlightData.elementWidth}px`,

						
		              	//borderInline: isImgHighlighted? "10px solid #fff8e7": 'none',
		              	//borderTop: isImgHighlighted? "10px solid #fff8e7": 'none',
		              	//borderBottom: isImgHighlighted? "10px solid #fff8e7": 'none',
		              }}
		            />
								</div>
								<CardContent>
					      </CardContent>
							</>
	      	:
      	    <>
				      { 
				      	/*
			        	content.comments.length>0&&content.comments[0].highlightData&&content.comments[0].highlightData.innerText
			          ?
			            <Typography variant="body1" style={{ backgroundColor: '#fff8e7', borderRadius: '10px', margin: '30px'}} component="p">
			            	{
			            		content.comments[0].highlightData.innerText.length > 100
			            		?
			            			content.comments[0].highlightData.innerText.substring(0, 100) + '...'
			            		:
			            			content.comments[0].highlightData.innerText
			            	}
			            </Typography>
			          :
			          	null
			          */
			        }
			        {
			        	/*
				          content.comments.length > 0 description
				          ?
				          	<div style={{display: 'flex', flexDirection: 'column', marginLeft: '15px', marginRight: '15px'}}>
					            <Comment contentId={content._id} comment={content.comments[0]} updateContent={updateContent}/>
					          </div>
				          :
				            null
			          */
			        }
							<div>
				        {
				        	thumbnail&&imgLoaded
				          ?
				            <img
				              src={thumbnail}
				              //component='img'
				              onError={
				              	e => setImgLoaded(false)
				              }
				              style ={{
				              	//borderRadius: '20px',
				              	width: '100%',
				              	//borderInline: isImgHighlighted? "10px solid #fff8e7": 'none',
				              	//borderTop: isImgHighlighted? "10px solid #fff8e7": 'none',
				              	//borderBottom: isImgHighlighted? "10px solid #fff8e7": 'none',
				              }}
				            />
				          :
				          	<div>
				          		<p style={{fontSize: '100px', color:'#e8e8e8'}}>
				          			{//content.title[0]
				          			}
				          		</p>
				          	</div>
				        }
						</div>
							<CardContent>
							{ 
					        	props.type !=='OBJECT'&&content.comments.length>0&&content.comments[0].selectedStringDatas.length>0
					          ?
					            <Typography style={{borderRadius: '10px', margin: '30px'}} align='left'>
					            	{
					            		content.comments[0].selectedStringDatas[0].selectedString > 100
					            		?
					            			content.comments[0].selectedStringDatas[0].selectedString.substring(0, 100) + '...'
					            		:
					            			content.comments[0].selectedStringDatas[0].selectedString
					            	}
					            </Typography>
					          :
					        	content.comments.length>0&&content.comments[0].highlightData
					        	?
					        		<Typography align='left' sx={{ textIndent: '0.5em', whiteSpace: 'pre-line'}}>
						        		{" " + content.comments[0].highlightData.innerText}
						        	</Typography>
					        	:
					        		null
					        }
					       </CardContent>
							</>
	      }
	      </CardActionArea>
	      {
        //<CommentForm content={content} url={props.url} updateContent={updateContent} setIsCommentFormClicked={setIsCommentFormClicked} /> 
      	}

      	{
	        props.type !=='OBJECT'&&content.comments.length>0
	        ?
	        	<Comment contentId={content._id} comment={content.comments[0]} updateContent={updateContent} loadedTime={props.loadedTime}/>
	        :
	        	null
		}
        {
        	/*
	        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
						<IconButton aria-label="thumb-up" onClick={handleContentLike}>
							{
								content.userReaction.length>0 && content.userReaction[0].type === 1 
								? 
	                <FavoriteIcon style={{color: 'red'}}/>
								:
	                <FavoriteBorderIcon  />
							}
						</IconButton>
						<Typography variant='body' style={{color: 'grey'}}>{content.likeCount} likes</Typography>
						{content.dislikeCount}
						<IconButton aria-label="thumb-down" onClick={handleContentDislike}>
							{
								content.userReaction.length>0 && content.userReaction[0].type === -1 
								? 
									<ThumbDownAltTwoToneIcon fontSize='small'/>
								:
									<ThumbDownIcon fontSize='small'/>
							}
						</IconButton>
	          {content.commentCount}             
	          <IconButton onClick={handleOpen}>
	            <ChatBubbleOutlineIcon />
	          </IconButton>
	            <Button onClick={handleCommentFormClick}>
	              comment
	            </Button>
	        </div>
          content.comments.length>0?
          	<SimpleComment contentId={content._id} comment={content.comments[0]} updateContent={updateContent} loadedTime={props.loadedTime}/>
          :
          	null
          */
        }
       </div>
		)
}

export default Content;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import { Text, Group } from 'react-konva';
import { Html } from 'react-konva-utils';




export default function Textbox(props) {
  const [input, setInput] = useState('');
  const [fontSize, setFontSize] = useState(120);
  const [fontColor, setFontColor] = useState('black');


  const handleTextChange = e => {
    setInput(e.target.value);
  }

  const handleFontSizeChange = e => {
    console.log(e.target.value)
    console.log(typeof(e.target.value))
    if(typeof(parseInt(e.target.value)) !== 'number'){

    }
    setFontSize(parseInt(e.target.value))
  }

  const handleFontColorChange = e => {
    setFontColor(e.target.value)
  }

  const handleDoneClick = () => {
    props.setIsTextboxOpen(false)
    props.updateTexts(draft => [...props.texts, {
      type: 'TEXT', 
      id: Date.now(),
      text: input, 
      x: props.x + 10,
      y: props.y + 100/props.scale,
      fontSize: fontSize,
      fontFamily: "Roboto",
      fill: fontColor,
    }])
  }

  const handleCancleClick = () => {
    props.setIsTextboxOpen(false);
  }

  return (
    <>
      <Group x={props.x} y={props.y}>
        <Html>
          <Box style={{transform: `scale(${1/props.scale})`, width: '400px'}}>
            <TextField
              id="outlined-multiline-flexible"
              label="text"
              multiline
              value={input}
              onChange={handleTextChange}
              sx={{marginRight: '5px'}}
            />
            <TextField
              onChange={handleFontSizeChange}
              defaultValue={fontSize}
              label="size"
              style={{width: '80px', marginRight: '5px'}}
            />
             <TextField
              id="outlined-multiline-flexible"
              onChange={handleFontColorChange}
              label="color"
              defaultValue={'black'}
              style={{width: '100px'}}
            />
            <Button onClick={handleDoneClick}>
              Done
            </Button>
             <Button onClick={handleCancleClick}>
              cancle
            </Button>
          </Box>
        </Html>
      </Group>
      <Text
        text={input}
        x={props.x +5/props.scale}
        y={props.y + 70/props.scale}
        fontSize={fontSize}
        fontFamily="Roboto"
        fill={fontColor}
      />
    </>
  );
}